<template>
  <form-input-search
    class="bottom-sheet-global-search__input"
    value=""
    :placeholder="getContent(layoutData, defaultLocaleLayoutData, 'header.search.placeholder')"
    readonly
    @click="toggleFilter('open')"
  />

  <layout-bottom-sheet
    ref="bottomSheet"
    height="80"
    bottomSheetClass="bottom-sheet-global-search"
    disableMainSwipe
    @closed="closed"
  >
    <template #header>
      <div class="bottom-sheet-global-search__title">
        {{ getContent(popupsData, defaultLocalePopupsData, 'globalSearch.title') }}
      </div>
    </template>

    <div class="bottom-sheet-global-search__header">
      <tab-global-search v-model="currentTab" />

      <form-input-search
        :value="searchValue"
        :hasClearIcon="!!searchValue"
        :placeholder="getContent(popupsData, defaultLocalePopupsData, 'globalSearch.searchPlaceholder')"
        @input="handleSearchInput"
      />
    </div>

    <div class="bottom-sheet-global-search__body" ref="bodyRef">
      <template v-if="isSheetLoaded">
        <modal-content-global-search-games
          v-if="currentTab === GlobalSearchTabs.GAMES"
          :searchValue="searchValue"
          @updateSearchValue="updateSearchValue"
        />

        <modal-content-global-search-sports v-else :searchValue="searchValue" @updateSearchValue="updateSearchValue" />
      </template>
    </div>

    <template #footer>
      <div class="custom-select-mobile__buttons">
        <button-base type="gray" size="md" @click="toggleFilter('close')">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.close') }}
        </button-base>
      </div>
    </template>
  </layout-bottom-sheet>
</template>

<script setup lang="ts">
  import debounce from 'lodash/debounce';

  import { scrollElemToTop } from '@skeleton/utils/scrollUtils';

  import { GlobalSearchTabs } from '@skeleton/consts/tabs/global-search-tabs';

  const { getContent } = useProjectMethods();
  const { popupsData, defaultLocalePopupsData, layoutData, defaultLocaleLayoutData } = storeToRefs(useGlobalStore());
  const globalSearchStore = useGlobalSearchStore();

  const route = useRoute();

  const bodyRef = ref();

  const currentTab = ref(GlobalSearchTabs.GAMES);

  const searchValue = ref('');
  const bottomSheet = ref();
  const isSheetLoaded = ref(false);

  const updateSearchValue = (value: string) => {
    searchValue.value = value;
  };

  const handleSearchInput = debounce(async value => {
    searchValue.value = value;
  }, 500);

  const toggleFilter = (methodName: 'open' | 'close') => {
    isSheetLoaded.value = methodName === 'open';

    bottomSheet.value[methodName]();
  };

  const closed = () => {
    isSheetLoaded.value = false;
    searchValue.value = '';
    currentTab.value = GlobalSearchTabs.GAMES;
    globalSearchStore.$reset();
  };

  watch(searchValue, () => {
    scrollElemToTop(bodyRef);
  });

  watch(
    () => route.query,
    () => {
      toggleFilter('close');
    }
  );
</script>

<style src="~/assets/styles/components/bottom-sheet/global-search.scss" lang="scss" />
